import {
  SELECTED_JOB_SITE_FOR_REQUEST,
  GET_REQUEST_CATEGORIES,
  GET_PRODUCT_DETAILS,
  GET_REQUEST_VIEW_DATA,
  DELETE_DOCUMENT,
  GET_SITE_MASTER_LIST_DATA,
  CLEAR_SELECTED_SITE,
  GET_CALL_OF_ORDER_POPUP_VISIBLE,
} from "./constant";
import { HIDE_POPUP, SHOW_POPUP } from "../loginData/constant";
import { INIT, LOADING, SUCCESS, ERROR } from "../../shared/utils/constant";
import { EDIT_TRANSPORT_ENQUIRY } from "../../modules/TransportOnlyForm/TransportOnlyForm.constants";
import { EDIT_ENQUIRY } from "../../modules/InquiryForm/InquiryForm.constants";

import {
  prepareRequestStages,
  prepareRequestTransport,
  prepareProduct,
  prepareSiteMasterData,
} from "../../shared/utils/methods";

export const initialState = {
  phase: INIT,
  showPopup: false,
  isCallOfOrderPopupVisible: false,
  relatedContact: {
    jobsite: {
      document: [],
      deleted_related_contact: [],
    },
    enquiry: {
      related_contact: [],
      document: [],
      deleted_related_contact: [],
    },
  },
  selectedJobSiteForRequest: {
    id: "",
    name: "",
    user_id: "",
    company_id: "",
    company_name: "",
    end_date: "",
    status_id: "",
  },
  requestCategoriesData: {
    category: [],
    stage: [],
    transport: [],
  },
  productData: [],
  requestViewQuote: [],
  siteMasterData: [],
  siteMasterDataRaw: [],
  apiUnAuthenticated: false,
  isRequestCategoriesLoading: false,
  isSiteMasterListLoading: false,
};

export const dataStore = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAILS.LOADING:
      return {
        ...state,
        phase: LOADING,
        apiUnAuthenticated: false,
      };
    case GET_SITE_MASTER_LIST_DATA.LOADING:
      return {
        ...state,
        phase: LOADING,
        isSiteMasterListLoading: true,
        apiUnAuthenticated: false,
      };
    case GET_REQUEST_CATEGORIES.LOADING: {
      return {
        ...state,
        phase: LOADING,
        isRequestCategoriesLoading: true,
      };
    }

    case GET_REQUEST_VIEW_DATA.LOADING: {
      return {
        ...state,
        phase: LOADING,
        isRequestViewPopupLoading: true,
        apiUnAuthenticated: false,
      };
    }

    case SHOW_POPUP: {
      return {
        ...state,
        showPopup: true,
      };
    }

    case HIDE_POPUP: {
      return {
        ...state,
        showPopup: false,
      };
    }

    case SELECTED_JOB_SITE_FOR_REQUEST: {
      const selectedSite = state.siteMasterDataRaw.filter(
        (site) => site.id === action.payload.id
      );

      return {
        ...state,
        selectedJobSiteForRequest: {
          id: action.payload.id,
          name: action.payload.name,
          start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
          duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          document: selectedSite.length > 0 ? selectedSite[0].documents : [],
        },
      };
    }

    case EDIT_ENQUIRY:
    case EDIT_TRANSPORT_ENQUIRY: {
      // required to hide popup.
      return {
        ...state,
        showPopup: false,
      };
    }
    case GET_REQUEST_CATEGORIES.SUCCESS: {
      const { data } = action;
      return {
        ...state,
        phase: SUCCESS,
        isRequestCategoriesLoading: false,
        requestCategoriesData: {
          category: data.category,
          stage: prepareRequestStages(data.stage),
          transport: prepareRequestTransport([data.transport]),
        },
      };
    }

    case GET_REQUEST_CATEGORIES.ERROR: {
      return {
        ...state,
        phase: ERROR,
        isRequestCategoriesLoading: false,
        apiUnAuthenticated: action.data.code === 401,
      };
    }

    case GET_PRODUCT_DETAILS.SUCCESS: {
      const response = [
        {
          id: action.data.id,
          data: prepareProduct(action.data.data),
        },
      ];

      return {
        ...state,
        phase: SUCCESS,
        productData: [...state.productData, ...response],
      };
    }

    case GET_PRODUCT_DETAILS.ERROR: {
      return {
        ...state,
        phase: ERROR,
        productData: [],
      };
    }

    case GET_REQUEST_VIEW_DATA.SUCCESS: {
      let viewData = {};

      if (action.data.lead) {
        const selectedSite = state.siteMasterDataRaw.filter(
          (site) => site.id === action.data.lead.id
        );
        viewData = {
          ...state,
          phase: SUCCESS,
          isRequestViewPopupLoading: false,
          requestViewQuote: [],
          requestCreationData: {
            ...action.data.leadRequest,
            transport: action.data.relatedTransport,
          },
          relatedContact: {
            ...state.relatedContact,
            enquiry: {
              ...state.relatedContact.enquiry,
              related_contact: action.data.relatedContact,
              document: action.data.document,
            },
          },
          selectedJobSiteForRequest: {
            id: action.data.lead.id,
            name: action.data.lead.name,
            user_id: action.data.lead.user_id,
            company_id: action.data.lead.company_id,
            company_name: action.data.lead.company_name,
            end_date: action.data.lead.end_date,
            status_id: action.data.lead.status_id,
            start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
            duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          },
          quoteValidatedCount: action.data.quoteValidatedCount,
        };
      } else {
        const selectedSite = state.siteMasterDataRaw.filter(
          (site) => site.id === action.data.jobsite.id
        );
        viewData = {
          ...state,
          phase: SUCCESS,
          isRequestViewPopupLoading: false,
          requestViewQuote: [],
          requestCreationData: {
            ...action.data.request,
            transport: action.data.relatedTransport,
          },
          relatedContact: {
            ...state.relatedContact,
            enquiry: {
              ...state.relatedContact.enquiry,
              related_contact: action.data.relatedContact,
              document: action.data.document,
            },
          },
          selectedJobSiteForRequest: {
            id: action.data.jobsite.id,
            name: action.data.jobsite.name,
            user_id: action.data.jobsite.user_id,
            company_id: action.data.jobsite.company_id,
            company_name: action.data.jobsite.company_name,
            end_date: action.data.jobsite.end_date,
            status_id: action.data.jobsite.status_id,
            start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
            duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          },
          quoteValidatedCount: action.data.quoteValidatedCount,
        };
      }

      return viewData;
    }

    case GET_REQUEST_VIEW_DATA.ERROR: {
      return {
        ...state,
        phase: ERROR,
        showPopup: false,
        isRequestViewPopupLoading: false,
        requestViewQuote: [],
      };
    }

    case GET_SITE_MASTER_LIST_DATA.SUCCESS: {
      return {
        ...state,
        phase: SUCCESS,
        isSiteMasterListLoading: false,
        siteMasterData: prepareSiteMasterData(action.data),
        siteMasterDataRaw: action.data,
      };
    }

    case GET_SITE_MASTER_LIST_DATA.ERROR: {
      return {
        ...state,
        phase: ERROR,
        isSiteMasterListLoading: false,
        siteMasterData: [],
        siteMasterDataRaw: [],
        apiUnAuthenticated: action.data.code === 401,
      };
    }

    case CLEAR_SELECTED_SITE: {
      return {
        ...state,
        selectedJobSiteForRequest: {
          id: "",
          name: "",
          duration: "",
          start_date: "",
        },
      };
    }

    case GET_CALL_OF_ORDER_POPUP_VISIBLE: {
      return {
        ...state,
        isCallOfOrderPopupVisible: action.payload,
      };
    }

    default:
      return state;
  }
};

export const showPopUp = () => ({
  type: SHOW_POPUP,
});

export const hidePopUp = () => ({
  type: HIDE_POPUP,
});

export const selectedJobSiteForRequestCreation = (payload) => ({
  type: SELECTED_JOB_SITE_FOR_REQUEST,
  payload,
});

export const getCategoriesData = () => ({
  type: GET_REQUEST_CATEGORIES.LOADING,
});

export const getProductData = (payload) => ({
  type: GET_PRODUCT_DETAILS.LOADING,
  payload,
});

export const getRequestViewData = (payload) => ({
  type: GET_REQUEST_VIEW_DATA.LOADING,
  payload,
});

export const deleteDocument = (payload) => ({
  type: DELETE_DOCUMENT.LOADING,
  payload,
});

export const getSiteMasterListData = () => ({
  type: GET_SITE_MASTER_LIST_DATA.LOADING,
});

export const getCallOfOrderPopupVisible = (payload) => ({
  type: GET_CALL_OF_ORDER_POPUP_VISIBLE,
  payload,
});
