import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import SiteData from "../SiteData";

import Button from "../../../../baseUI/Button";
import sitelogo from "../../../../../assets/shared/site.svg";

import { prepareFullDateFormat } from "../../../../utils/methods";

import { getRequestListingData } from "../../../../../modules/SiteOverview/SiteOverview.actionCreators";
import { selectedJobSiteForRequestCreation } from "../../../../../store/dataStore";
import {
  handleSiteFormDetails,
  handleEditJobSite,
} from "../../../../../modules/JobsiteForm/JobsiteForm.actionCreators";
import { createEnquiry } from "../../../../../modules/InquiryForm/InquiryForm.actionCreators";

import "./SiteRow.scss";

const SiteRow = (props) => {
  const { data, inActiveData, t } = props;
  const [isOpen, setOpenStatus] = useState(false);

  const history = useHistory();
  const { pathname } = window.location;

  const renderDuration = (duration) => {
    const durationOptions = {
      "1_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_MONTH"
      ),
      "2_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_MONTHS"
      ),
      "3_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_3_MONTHS"
      ),
      "6_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_6_MONTHS"
      ),
      "1_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_YEAR"
      ),
      "2_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_YEARS"
      ),
      MORE: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_MORE"),
    };

    return durationOptions[duration] ? durationOptions[duration] : "";
  };

  const handleToggle = () => {
    setOpenStatus(!isOpen);
  };

  const editSite = (id) => {
    const dataLocal = { ...props.data };
    if (!props.data.document) {
      dataLocal.document = [];
    }
    props.handleSiteFormDetails({ updatedData: dataLocal });
    props.handleEditJobSite(id);
    history.push("/createjobsite");
  };

  const getJobSite = async (id, name) => {
    if (window.location.pathname === "/createenquiry") {
      props.createEnquiry();
      props.selectedJobSiteForRequestCreation({ id, name });
    } else {
      await props.getRequestListingData({ id });
    }
  };

  return (
    <>
      <div className="row list-row">
        <div className="col-xs-12 col-sm-12 col-md-6">
          <div className="column-text list-site-en">
            {data.requests_count === 0 && (
              <div className="float-left dropdown-btn-placeholder" />
            )}
            {data.requests_count > 0 && (
              <>
                {/* eslint-disable-next-line */}
                <button
                  className="border-0 expand-btn position-relative float-left site-hide"
                  data-toggle="collapse"
                  data-target={`#collapse${data.id}`}
                  aria-expanded="false"
                  aria-controls={`collapse${data.id}`}
                  type="button"
                  onClick={() => {
                    handleToggle();
                  }}
                >
                  <i
                    className={isOpen ? "fa fa-angle-up" : "fa fa-angle-down"}
                    aria-hidden="true"
                  />
                </button>
              </>
            )}
            {pathname === "/sitesandenquiries" ? (
              <div
                onClick={() => {
                  localStorage.setItem("jobsiteId", data.id);
                  history.push("/viewsite");
                }}
                role="button"
                tabIndex="0"
              >
                <div className="media data-list-item border-bottom-0 p-0">
                  <div
                    className="media-img p-0 rounded-0"
                    style={{ marginRight: "7px" }}
                  >
                    <img className="m-0" src={sitelogo} alt="enquiryBtn" />
                  </div>

                  <div className="media-body jobsite-info">
                    <div className="jobsite-name-wrapper">
                      <h4 className="m-0 p-0 d-inline-block jobsite-name">
                        {data.name}
                      </h4>
                      <span className="jobsite-id">{`#${data.id}`}</span>
                    </div>
                    {parseInt(localStorage.getItem("userId"), 10) ===
                      parseInt(data.user_id, 10) && (
                      <div className="jobsite-btn-wrapper">
                        <div
                          style={{ marginRight: "7px" }}
                          className="fontLato14"
                          onClick={(e) => {
                            e.stopPropagation();
                            editSite(data.id);
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          <button
                            className="btn btn-primary btn-sm edit-enquiry-btn btn-transparent"
                            type="button"
                          >
                            <i className="fa fa-pencil pr-2" aria-hidden="true" />
                            {t("CLIENT_PLATFORM_SITE_LIST_BUTTON_EDIT")}
                          </button>
                          {/* eslint-disable-next-line */}
                          <button
                            className="btn btn-primary btn-sm edit-enquiry-btn btn-transparent edit-site"
                            type="button"
                          >
                            <i
                              style={{ padding: "1px 5px", fontSize: "19px" }}
                              className="fa fa-pencil"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className="col-6 col-md-2"
          onClick={() => {
            localStorage.setItem("jobsiteId", data.id);
            history.push("/viewsite");
          }}
          role="button"
          tabIndex="0"
        >
          <span className="d-xs-inline-block d-md-none title mt-3">
            {t("CLIENT_PLATFORM_SITE_LIST_TABLE_SITE_START_DURATION")}
          </span>
          <div className="column-text">
            {prepareFullDateFormat(data.start_date)} <br />
            <span className="fontLato12 gray-text">
              {renderDuration(data.duration)}
            </span>
          </div>
        </div>
        <div
          className="col-6 col-md-2"
          onClick={() => {
            localStorage.setItem("jobsiteId", data.id);
            history.push("/viewsite");
          }}
          role="button"
          tabIndex="0"
        >
          <span className="d-xs-inline-block d-md-none title mt-3">
            {t("CLIENT_PLATFORM_SITE_LIST_TABLE_SITE_INQUIRIES")}
          </span>
          <div className="column-text">
            {data.requests_count <= 1 &&
              `${data.requests_count} ${t("CLIENT_PLATFORM_SITE_LIST_INQUIRY")}`}
            {data.requests_count > 1 &&
              `${data.requests_count} ${t("CLIENT_PLATFORM_SITE_LIST_INQUIRIES")}`}
          </div>
        </div>
        <div
          className={`col-xs-12 col-sm-12 col-md-2 ${
            data.quotes_count > 0 ? "p-0" : ""
          }`}
        >
          <div
            className={`column-text action ${data.quotes_count > 0 ? "p-0" : ""}`}
          >
            {!inActiveData && (
              <Button
                className="btn btn-primary btn-blue btn-sm create-enquiry-btn"
                name={t("CLIENT_PLATFORM_SITE_LIST_BUTTON_NEW_INQUIRY")}
                onClick={(e) => {
                  e.stopPropagation();
                  props.createEnquiry();
                  localStorage.setItem(
                    "selectedJobsiteForRequest",
                    JSON.stringify({ id: data.id, name: data.name })
                  );
                  props.selectedJobSiteForRequestCreation({
                    id: data.id,
                    name: data.name,
                  });
                  history.push("/createenquiry");
                }}
              />
            )}

            {data.quotes_count > 0 && !inActiveData && (
              <NavLink to="/createcallofforder">
                <Button
                  className="btn btn-primary btn-blue btn-sm create-enquiry-btn ml-1"
                  name="Create Call-Off Order"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    getJobSite(data.id, data.name);
                  }}
                />
              </NavLink>
            )}
          </div>
        </div>
        <div className="collapse col-12 site-hide" id={`collapse${data.id}`}>
          {data.requests && (
            <SiteData
              id={data.id}
              data={data.requests}
              count={data.requests_count}
            />
          )}
        </div>
      </div>
    </>
  );
};

SiteRow.propTypes = {
  data: PropTypes.shape(PropTypes.object).isRequired,
  inActiveData: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  handleEditJobSite: () => handleEditJobSite(),
  handleSiteFormDetails: (payload) => handleSiteFormDetails(payload),
  selectedJobSiteForRequestCreation: (payload) =>
    selectedJobSiteForRequestCreation(payload),
  createEnquiry: () => createEnquiry(),
  getRequestListingData: (payload) => getRequestListingData(payload),
};

export default connect(null, mapDispatchToProps)(withTranslation()(SiteRow));
