import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SectionHeader from "../../../../shared/components/SectionHeader";
import AddNew from "../../../../shared/components/AddNew";
import EmptyView from "../../../../shared/components/EmptyView";

import enquiryBtn from "../../../../assets/shared/enquiry.svg";
import removalIcon from "../../../../assets/shared/removal.svg";
import supplyIcon from "../../../../assets/shared/supply.svg";

import { getEnquiryStatusList } from "../../../../shared/utils/methods";
import { ACTIVE_USER, PENDING_USER } from "../../../../shared/utils/constant.js";

import "./MyEnquiries.scss";

const MyEnquiries = (props) => {
  const { enquiries, isActiveSitePresent, t, isButtonDisabled, userStatus } = props;

  const status = getEnquiryStatusList();

  const getQuoteStatusColor = (quoteStatus) => {
    if (quoteStatus === 1) {
      return "quote-status in-progress";
    }
    if (quoteStatus === 2) {
      return "quote-status quote-sent";
    }
    if (quoteStatus === 3) {
      return "quote-status won";
    }
    return "quote-status";
  };

  const renderEnquiries = () => {
    return (
      <ul className="dashboard-enquiries">
        {enquiries
          .filter((item, i) => i < 5)
          .map((item, i) => {
            return (
              <li
                key={item.id}
                className="enquiry-item"
                onClick={(e) => {
                  if (userStatus === PENDING_USER) {
                    props.viewRequest(item.lead_id, item.id);
                  } else {
                    props.viewRequest(item.jobsite_id, item.id);
                  }
                }}
              >
                <img
                  className="enquiry-img"
                  src={item.service_id === 1 ? removalIcon : supplyIcon}
                  alt="Site Enquiry"
                />
                <div className="enquiry-details-wrapper">
                  <div className="title-status-wrapper">
                    <div className="title-wrapper">
                      <span className="enquiry-name">
                        {item.name !== "Unknown"
                          ? item.name
                          : t("CLIENT_PLATFORM_REQUEST_TYPE_UNKNOWN")}
                      </span>
                      {userStatus === ACTIVE_USER && (
                        <span className="enquiry-id">#{item.id}</span>
                      )}
                    </div>
                    {item.step && userStatus === ACTIVE_USER && (
                      <span className={getQuoteStatusColor(item.step)}>
                        {t(status[item.step])}
                      </span>
                    )}
                  </div>
                  <p className="enquiry-description">
                    <span>
                      {item.service_id === 1
                        ? t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_REMOVAL")
                        : t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_SUPPLY")}
                      &nbsp;:
                    </span>
                    <span className="gray-darker-text pl-1">
                      {t(item.category_name)} - {t(item.sub_category_name)}
                    </span>
                  </p>
                </div>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <section className="my-enquiries">
      <SectionHeader
        title={t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_SECTION_TITLE")}
        link="/sitesandenquiries"
        viewAllTitle={t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_BUTTON_ALL_SITES")}
        isViewAllVisible={!!(userStatus === ACTIVE_USER && enquiries.length > 0)}
      />
      {!isActiveSitePresent &&
        enquiries.length === 0 &&
        userStatus === ACTIVE_USER && (
          <EmptyView
            link="/createjobsite"
            description={t(
              "CLIENT_PLATFORM_DASHBOARD_INQUIRIES_NO_SITE_DESCRIPTION"
            )}
            title={t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_NO_SITE_TITLE")}
            icon={enquiryBtn}
            buttonName={t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_BUTTON_NEW_SITE")}
            isBtnVisible
            btnDisabled={false}
            onBtnClick={() => {
              props.createSite();
            }}
          />
        )}
      {isActiveSitePresent &&
        enquiries.length === 0 &&
        userStatus === ACTIVE_USER && (
          <EmptyView
            link="/createenquiry"
            description={t(
              "CLIENT_PLATFORM_DASHBOARD_INQUIRIES_NO_INQUIRY_DESCRIPTION"
            )}
            title={t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_NO_INQUIRY_TITLE")}
            icon={enquiryBtn}
            buttonName={t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_BUTTON_NEW_INQUIRY")}
            isBtnVisible
            btnDisabled={isButtonDisabled}
            onBtnClick={() => {
              props.createEnquiry();
            }}
          />
        )}
      {enquiries.length > 0 && renderEnquiries()}
      {isActiveSitePresent && enquiries.length > 0 && (
        <AddNew
          link="/createenquiry"
          name={t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_LIST_BUTTON_NEW_INQUIRY")}
          onAdd={() => {
            props.createEnquiry();
            props.clearSelectedSite();
          }}
        />
      )}
    </section>
  );
};

MyEnquiries.propTypes = {
  enquiries: PropTypes.array,
  isActiveSitePresent: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
  viewRequest: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
  createEnquiry: PropTypes.func.isRequired,
  clearSelectedSite: PropTypes.func.isRequired,
};

export default withTranslation()(MyEnquiries);
