import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

import Header from "../../../../shared/components/Header";
import RequestViewPopUp from "../../../../shared/components/RequestViewPopUp";

import Loader from "../../../../shared/baseUI/Loader";
import Popup from "../../../../shared/baseUI/Popup";

import { prepareSortingDate } from "../../../../shared/utils/methods";
import {
  ACTIVE_USER,
  PENDING_USER,
  TO_BE_PROCESS_COMPLETE_LEADS,
} from "../../../../shared/utils/constant.js";

import { storage } from "../../../../store/dataStore/api";

import ContextualAssistant from "../ContextualAssistant";
import MySites from "../MySites";
import MyEnquiries from "../MyEnquiries";
import MyCallOfOrders from "../MyCallOfOrders";
import MyQuotes from "../MyQuotes";
import MyRequestsQuotation from "../MyRequestsQuotation";
import LeadViewPopup from "../LeadViewPopup";

import "./DashboardHome.scss";

const DashboardHome = (props) => {
  const {
    isLoading,
    userId,
    siteData,
    enquiryData,
    quoteData,
    userDetails,
    showPopup,
    lead,
    isLeadFlow,
    isCallOfOrder,
    apiUnAuthenticated,
    t,
    isCallOfOrderPopupVisible,
  } = props;

  const [recentSiteData, setSiteData] = useState([]);
  const [recentRequestData, setRequestData] = useState([]);
  const [showIsCallOfOrderPopup, setShowIsCallOfOrderPopup] = useState(false);
  const [isLeadViewVisible, setLeadViewVisibility] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview("/dashboard");
    if (localStorage.getItem("userId") && localStorage.getItem("userId") !== "") {
      storage();
      props.getCategoriesData();
      props.getSiteMasterListData();
      if (parseInt(userDetails.status, 10) === ACTIVE_USER) {
        props.getDashboardData(userDetails.id);
      }
      if (parseInt(userDetails.status, 10) === PENDING_USER) {
        props.getDashboardLeads(userDetails.id);
      }
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  useEffect(() => {
    setSiteData(siteData);
  }, [siteData]);

  useEffect(() => {
    setRequestData(prepareSortingDate(enquiryData));
  }, [enquiryData]);

  useEffect(() => {
    if (userId === null || apiUnAuthenticated) {
      props.handleLogOut();
      history.replace("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, apiUnAuthenticated]);

  // Handle lead data from step 3.
  // This effect is invoked when user is redirected to login after step 3 of onboarding simplified flow
  useEffect(() => {
    if (lead && !Array.isArray(lead)) {
      if (isLeadFlow) {
        if (lead.leadInfo.status === 3) {
          // Redirect to create enquiry.
          const jobsiteSelected = {
            id: lead.leadInfo.jobsite_id,
            name: lead.leadInfo.jobsite_name,
            start_date: lead.leadInfo.start_date,
            duration: lead.leadInfo.duration,
          };
          props.editEnquiry({
            jobsite: jobsiteSelected,
            document: lead.document ? lead.document : [],
            related_contact: [],
            leadInfo: lead.leadInfo,
          });
          history.push("/createenquiry");
        } else {
          props.handleEditJobSite({
            leadInfo: lead.leadInfo,
            leadDocuments: lead.document,
          });
          history.push("/createjobsite");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead, isLeadFlow]);

  useEffect(() => {
    setShowIsCallOfOrderPopup(isCallOfOrderPopupVisible);
  }, [isCallOfOrderPopupVisible]);

  const closeCallOfOrderPopUp = () => {
    props.getCallOfOrderPopupVisible(false);
  };

  const viewJobSite = async (id) => {
    localStorage.setItem("jobsiteId", id);
  };

  const viewRequest = async (siteId, id) => {
    props.handleShowPopUp();
    await props.getRequestViewData({ siteId, id });
  };

  const showLead = (id) => {
    const selectLead = props.leadList.find((leadItem) => leadItem.id === id);
    setSelectedLead(selectLead);
    setLeadViewVisibility(true);
  };

  const hideLead = () => {
    setLeadViewVisibility(false);
  };

  const getProcessCompleteLeads = () => {
    const leadsFiltered = props.leadList.filter(
      (leadItem) => parseInt(leadItem.status, 10) === TO_BE_PROCESS_COMPLETE_LEADS
    );
    return leadsFiltered.length > 0;
  };

  return (
    <div className="container-fluid">
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <section className="row dashboard-details-container">
          <div className="col-xs-12 col-sm-12 col-md-6 col-xl-4">
            <ContextualAssistant
              userDetails={userDetails}
              quoteData={quoteData}
              siteData={siteData}
              enquiryData={enquiryData}
              isCallOfOrder={isCallOfOrder}
              createSite={props.createSite}
              createEnquiry={props.createEnquiry}
              createCalloffOrderForm={props.createCalloffOrderForm}
              leadList={props.leadList}
            />

            {isCallOfOrder > 0 &&
              parseInt(userDetails.status, 10) === ACTIVE_USER && (
                <div className="show-mobile" aria-label="">
                  <MyCallOfOrders
                    callOfOrders={[]}
                    isButtonDisabled={isCallOfOrder <= 0}
                    createCalloffOrderForm={props.createCalloffOrderForm}
                  />
                </div>
              )}
            <div className="show-mobile">
              {parseInt(userDetails.status, 10) === ACTIVE_USER && (
                <MyQuotes quotes={quoteData} />
              )}
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-xl-4">
            {parseInt(userDetails.status, 10) === ACTIVE_USER && (
              <MySites
                sites={recentSiteData}
                createSite={props.createSite}
                viewJobSite={viewJobSite}
              />
            )}
            {parseInt(userDetails.status, 10) === PENDING_USER && (
              <MyRequestsQuotation
                viewLead={showLead}
                requestQuotations={props.leadList}
                isLeadListLoading={props.isLeadListLoading}
                viewJobSite={viewJobSite}
              />
            )}
            {isCallOfOrder > 0 &&
              parseInt(userDetails.status, 10) === ACTIVE_USER && (
                <div className="show-desktop">
                  <MyCallOfOrders
                    callOfOrders={[]}
                    createCalloffOrderForm={props.createCalloffOrderForm}
                    isButtonDisabled={isCallOfOrder <= 0}
                  />
                </div>
              )}

            <div className="show-mobile">
              {parseInt(userDetails.status, 10) === ACTIVE_USER && (
                <MyEnquiries
                  enquiries={recentRequestData}
                  viewRequest={viewRequest}
                  createSite={props.createSite}
                  isActiveSitePresent={recentSiteData.length > 0}
                  createEnquiry={props.createEnquiry}
                  clearSelectedSite={props.clearSelectedSite}
                  isButtonDisabled={recentSiteData.length === 0}
                  userStatus={parseInt(userDetails.status, 10)}
                />
              )}
              {/* Lead requests */}
              {parseInt(userDetails.status, 10) === PENDING_USER &&
                props.leadRequestList.length > 0 && (
                  <MyEnquiries
                    enquiries={props.leadRequestList}
                    viewRequest={viewRequest}
                    createSite={props.createSite}
                    isActiveSitePresent={getProcessCompleteLeads()}
                    createEnquiry={props.createEnquiry}
                    clearSelectedSite={props.clearSelectedSite}
                    isButtonDisabled={recentSiteData.length === 0}
                    userStatus={parseInt(userDetails.status, 10)}
                  />
                )}
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-xl-4">
            <div className="row enquiries-container">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                {parseInt(userDetails.status, 10) === ACTIVE_USER && (
                  <MyEnquiries
                    enquiries={recentRequestData}
                    viewRequest={viewRequest}
                    createSite={props.createSite}
                    isActiveSitePresent={recentSiteData.length > 0}
                    createEnquiry={props.createEnquiry}
                    clearSelectedSite={props.clearSelectedSite}
                    isButtonDisabled={recentSiteData.length === 0}
                    userStatus={parseInt(userDetails.status, 10)}
                  />
                )}
                {/* Lead requests */}
                {parseInt(userDetails.status, 10) === PENDING_USER &&
                  props.leadRequestList.length > 0 && (
                    <MyEnquiries
                      enquiries={props.leadRequestList}
                      viewRequest={viewRequest}
                      createSite={props.createSite}
                      isActiveSitePresent={getProcessCompleteLeads()}
                      createEnquiry={props.createEnquiry}
                      clearSelectedSite={props.clearSelectedSite}
                      isButtonDisabled={recentSiteData.length === 0}
                      userStatus={parseInt(userDetails.status, 10)}
                    />
                  )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                {parseInt(userDetails.status, 10) === ACTIVE_USER && (
                  <MyQuotes quotes={quoteData} />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {showPopup && <RequestViewPopUp />}
      {isLeadViewVisible && (
        <LeadViewPopup
          hideLead={hideLead}
          lead={{ ...selectedLead }}
          createSite={props.createSite}
        />
      )}
      {showIsCallOfOrderPopup && (
        <Popup
          modalParentClass="modal-parent"
          modalClass="modal-box"
          title={t(
            "CLIENT_PLATFORM_NAVIGATION_MENU_CALL_OFF_ORDER_NOT_AVAILABLE_TITLE"
          )}
          name="isCallOfOrderNav"
          content={t(
            "CLIENT_PLATFORM_NAVIGATION_MENU_CALL_OFF_ORDER_NOT_AVAILABLE_CONTENT"
          )}
          id="isCallOfOrderNavPopup"
          onClose={closeCallOfOrderPopUp}
        />
      )}
    </div>
  );
};

DashboardHome.propTypes = {
  userId: PropTypes.string.isRequired,
  siteData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  enquiryData: PropTypes.array.isRequired,
  quoteData: PropTypes.array.isRequired,
  lead: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLeadFlow: PropTypes.bool,
  isCallOfOrder: PropTypes.number,
  showPopup: PropTypes.bool,
  apiUnAuthenticated: PropTypes.bool,
  userDetails: PropTypes.object.isRequired,
  isCallOfOrderPopupVisible: PropTypes.bool,
  getDashboardData: PropTypes.func.isRequired,
  getCategoriesData: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
  createEnquiry: PropTypes.func.isRequired,
  createCalloffOrderForm: PropTypes.func.isRequired,
  editEnquiry: PropTypes.func.isRequired,
  handleShowPopUp: PropTypes.func.isRequired,
  getRequestViewData: PropTypes.func.isRequired,
  clearSelectedSite: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  handleEditJobSite: PropTypes.func.isRequired,
  getCallOfOrderPopupVisible: PropTypes.func.isRequired,
  getSiteMasterListData: PropTypes.func.isRequired,
};

export default withTranslation()(DashboardHome);
