import * as moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const prepareAlphabetsSpecialCharacters = (value) => {
  return !!value.replace(/[^0-9]/g, "");
};

export const prepareCountries = (data) => {
  return data.map((i) => ({ label: i.name, value: i.id, id: i.id }));
};

export const prepareActivities = (data) => {
  return data.map((i) => ({ label: i.name, value: i.id }));
};

export const prepareSortingDate = (data) => {
  return data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
};

export const prepareTimeFormat = (data) => {
  return moment(data, ["HH.mm"]).format("HH:mm");
};

export const generateSiteHours = (startTime, endTime, step) => {
  const items = [];
  for (let hourIndex = startTime; hourIndex < endTime; hourIndex++) {
    let minStart = 0;
    while (minStart < 60) {
      const hour = hourIndex < 10 ? `0${hourIndex}` : hourIndex;
      const mins = minStart < 10 ? `0${minStart}` : minStart;
      const hourMin = `${hour}:${mins}`;
      items.push({ label: hourMin, value: hourMin });
      minStart += step;
    }
  }
  return items;
};

export const prepareDocumentLabel = (data) => {
  let result = [];
  try {
    result = data.map((x) => x);
  } catch {
    return result;
  }
  return result;
};

export const prepareDocumentList = (data) => {
  let result = [];
  if (data.length > 0) {
    const existingData = data.filter((x) => x.id !== "");
    const filterObj = data.filter((x) => x.id === "");
    if (filterObj.length !== 0) {
      const { type } = filterObj[0];
      result = filterObj.map((x) => {
        return x.file.map((item) => {
          return {
            id: uuidv4(),
            type,
            file: item,
          };
        });
      });
      result = [...existingData, ...result];
    } else {
      result = [...existingData];
    }
    return result;
  }
  return [];
};

export const prepareShortDateFormat = (date) => {
  let result = "";
  try {
    // For Swiss, use fr instead of fr-ch locale. HS-1469
    const locale = moment.locale() === "fr-ch" ? "fr" : moment.locale();
    result = moment(date).locale(locale).format("Do MMM YYYY");
  } catch {
    return result;
  }
  return result;
};

export const prepareFullDateFormat = (date) => {
  let result = "";
  try {
    // For Swiss, use fr instead of fr-ch locale. HS-1469
    const locale = moment.locale() === "fr-ch" ? "fr" : moment.locale();
    result = moment(date).locale(locale).format("Do MMMM YYYY");
  } catch {
    return result;
  }
  return result;
};

export const pickObj = (obj, keys) => {
  return keys
    .map((k) => (k in obj ? { [k]: obj[k] } : {}))
    .reduce((res, o) => Object.assign(res, o), {});
};

export const rejectObj = (obj, keys) => {
  const filteredKeys = Object.keys(obj).filter((k) => !keys.includes(k));
  return pickObj(obj, filteredKeys);
};

export const prepareDateDifference = (start, end) => {
  return moment(end.split("-")).diff(moment(start.split("-")), "days");
};

export const getWeekDays = () => {
  return [
    {
      label:
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD_MONDAY",
      value: "Monday",
    },
    {
      label:
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD_TUESDAY",
      value: "Tuesday",
    },
    {
      label:
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD_WEDNESDAY",
      value: "Wednesday",
    },
    {
      label:
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD_THURSDAY",
      value: "Thursday",
    },
    {
      label:
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD_FRIDAY",
      value: "Friday",
    },
    {
      label:
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD_SATURDAY",
      value: "Saturday",
    },
    {
      label:
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD_SUNDAY",
      value: "Sunday",
    },
  ];
};

export const getDaysBetweenDates = (days, startDay, endDay) => {
  let week = getWeekDays();
  if (days < 6) {
    let firstIndex;
    week.forEach((day, index) => {
      if (day.value === startDay) {
        firstIndex = index;
      }
    }); // Find first day
    week = week.concat(week.splice(0, firstIndex)); // Shift array so that first day is index 0
    let lastIndex;
    // Find last day
    week.forEach((day, index) => {
      if (day.value === endDay) {
        lastIndex = index;
      }
    });
    return week.slice(0, lastIndex + 1);
  }
  return week;
  // Cut from first day to last day
};

export const prepareTabListData = (data) => {
  let tabData = [{}];
  if (data.length > 0) {
    tabData = data.map((list) => {
      return {
        title: list,
        id: `pills-${list}-tab`,
        link: `#pills-${list}`,
        role: "tab",
        controls: `pills-${list}`,
      };
    });
  }
  return tabData;
};

export const prepareCategories = (data) => {
  let result = [];
  try {
    result = data.map((x) => {
      return {
        ...x,
      };
    });
  } catch {
    return result;
  }
  return result;
};

export const prepareRequestStages = (data) => {
  return data.map((i) => ({ label: i.name, value: i.id }));
};

export const prepareRequestTransport = (data) => {
  const updatedData = [].concat.apply([], Object.values(data[0]));
  return updatedData.map((i) => ({
    label: i.name,
    value: i.id,
    type: i.river,
  }));
};
export const prepareUnits = (data) => {
  return data.map((i) => ({
    label: i.name,
    value: i.unit.toUpperCase(),
  }));
};

export const prepareNumValidation = (evt) => {
  const numbers = /^[0-9]+$/;
  let charCode;
  if (evt.target.value.match(numbers)) {
    return true;
  }
  if (evt) {
    charCode = evt.which ? evt.which : evt.keyCode;
  } else {
    charCode = window.event.which ? window.event.which : window.event.keyCode;
  }
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

export const prepareUsage = (data) => {
  return data.map((i) => ({ label: i.name, value: i.id }));
};

export const prepareProduct = (data) => {
  return data.map((i) => ({ label: i.name, value: i.id }));
};

export const preparePaginationData = (data) => {
  return {
    current_page: data.current_page,
    first_page_url: data.first_page_url,
    from: data.from,
    last_page: data.last_page,
    last_page_url: data.last_page_url,
    next_page_url: data.next_page_url,
    path: data.path,
    per_page: data.per_page,
    prev_page_url: data.prev_page_url,
    to: data.to,
    total: data.total,
  };
};

export const prepareTransportProduct = (data) => {
  return data.map((i) => ({ label: i.name, value: i.id }));
};

export const prepareSiteMasterData = (data) => {
  return data.map((i) => ({
    label: `${i.id} - ${i.name}`,
    value: `${i.id} - ${i.name}`,
  }));
};

export const prepareRequestMasterData = (data) => {
  return data.map((i) => ({
    label: `${i.service} #${i.id} - ${i.name}`,
    value: `${i.service} #${i.id} - ${i.name}`,
  }));
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getDurationOptions = (t) => {
  return [
    {
      value: "1_MONTH",
      label: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_1_MONTH"),
    },
    {
      value: "2_MONTH",
      label: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_2_MONTHS"),
    },
    {
      value: "3_MONTH",
      label: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_3_MONTHS"),
    },
    {
      value: "6_MONTH",
      label: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_6_MONTHS"),
    },
    {
      value: "1_YEAR",
      label: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_1_YEAR"),
    },
    {
      value: "2_YEAR",
      label: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_2_YEARS"),
    },
    {
      value: "MORE",
      label: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_MORE"),
    },
  ];
};

export const getEnquiryStatusList = () => {
  return [
    "",
    "CLIENT_PLATFORM_INQUIRY_STATUS_IN_PROGRESS",
    "CLIENT_PLATFORM_INQUIRY_STATUS_QUOTE_SENT",
    "CLIENT_PLATFORM_INQUIRY_STATUS_VALIDATED",
  ];
};

export const getQuoteStatusList = () => {
  return [
    "",
    "",
    "CLIENT_PLATFORM_QUOTE_STATUS_SUBMITTED",
    "CLIENT_PLATFORM_QUOTE_STATUS_QUOTE_VALIDATED",
    "",
    "CLIENT_PLATFORM_QUOTE_STATUS_QUOTE_CANCELED",
    "CLIENT_PLATFORM_QUOTE_STATUS_QUOTE_REFUSED",
    "CLIENT_PLATFORM_QUOTE_STATUS_QUOTE_REFUSED",
  ];
};

export const getStorageInfo = () => {
  return {
    token: localStorage.getItem("token") !== "" ? localStorage.getItem("token") : "",
    id: localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : "",
  };
};

export const getAllowedDocTypes = () => {
  return [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-outlook",
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];
};

export const getAllowedFileTypes = () => {
  return ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .msg, .pdf, .jpg, .jpeg, .png";
};

export const getUnits = (t) => {
  return [
    { name: t("M3"), unit: "M3" },
    { name: t("TONS"), unit: "TONS" },
    { name: t("LOADS"), unit: "LOADS" },
    { name: t("UNIT"), unit: "UNIT" },
    { name: t("PACKAGE"), unit: "PACKAGE" },
    { name: t("DAY"), unit: "DAY" },
  ];
};

export const renderPriceWithCurrency = (price, unit, currency, position) => {
  if (parseInt(position, 10) !== 1) {
    return `${currency} ${price} /${unit}`;
  }

  return `${price} ${currency}/${unit}`;
};
